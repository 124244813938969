import logo from '../images/logo.png';

let config = {}
//Live
config['BASE_URL'] = 'https://api-hrms.dtsmis.in:3008/';
config['API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/admin/';
config['CANDIDATE_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/candidate/';
config['PANEL_URL'] = 'https://api-hrms.dtsmis.in:3008/'
config['IMAGE_PATH'] = 'https://api-hrms.dtsmis.in:3008/public/uploads/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
config['GLOB_API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/global/';

config['LOGO_PATH'] = logo;

config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HR Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'privacy-policy';
config['TERMS_URL'] = 'terms-conditions';
config['COMPANY_NAME'] = 'HLFPPT HRMS';
config['GOOGLE_MAP_KEY'] = 'AIzaSyDjkMMcPgRXYBLKaUf6finQlPubrbNbvbw'; 


// export default config;

// config['BASE_URL'] = 'https://hrapi.hlfppt.org/';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/admin/';
// config['PANEL_URL'] = 'https://hrapi.hlfppt.org/'
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['GLOB_API_URL'] = 'https://hrapi.hlfppt.org/v1/global/';
 

// config['LOGO_PATH'] = logo;

// config['COMPANY_NAME'] = 'HRMS WEB APP';  
// config['PANEL_NAME'] = 'HRMS Login';
// config['HELP_URL'] = 'support';
// config['PRIVACY_URL'] = 'privacy-policy';
// config['TERMS_URL'] = 'terms-conditions';
// config['COMPANY_NAME'] = 'HLFPPT HRMS';
// config['GOOGLE_MAP_KEY'] = 'AIzaSyDjkMMcPgRXYBLKaUf6finQlPubrbNbvbw'; 


export default config;