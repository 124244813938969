import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Link } from 'react-router-dom';
import { PiDownloadSimpleFill } from "react-icons/pi";
import ApprovalModal from "./ApprovalModal"
import { ManPowerAcquisitionsSlice } from '../slices/JobSortLIstedSlice/SortLIstedSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import config from '../../config/config';
import { FaEye } from 'react-icons/fa';



export default function RequisitionTable() {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams()
    const [imageUrl, setImageUrl] = useState(null);

    const { ManPowerRequisition } = useSelector((state) => state.shortList)

    useEffect(() => {
        let Payloads = {
            "keyword": "",
            "page_no": "1",
            "per_page_record": "1000", "scope_fields": [],
            "status": "",
            "project_name": searchParams.get('project_name') ? searchParams.get('project_name') : '',
            "project_id": searchParams.get('project_id') ? searchParams.get('project_id') : ''
        }
        dispatch(ManPowerAcquisitionsSlice(Payloads))
    }, [dispatch, searchParams])



    const handleShow = (e, data) => {
        e.preventDefault()
        setShow(true)
        setVisible(data);
    };

    const handleOpenPreviewImage = (e, data) => {
        setOpen(true)
        setImageUrl(data)
    }

    const changeTypeOfOpening = (type) => {
           if(type === 'new'){
              return 'New Opening'
           }else if(type === 'replacement') {
              return 'Replacement'
           }else if(type === 'plant_non_budgeted') {
              return 'Planned Addition budgeted/Non-budgeted'
           }
    }


    const rows = ManPowerRequisition.status === 'success' && Array.isArray(ManPowerRequisition.data)
        ? ManPowerRequisition.data.map((key, index) => {
            return {
                id: index + 1,
                fID: index + 1,
                projectDepartment: {
                    name: key?.project_name,
                    department: key?.department_name,
                },
                placeofPosting: {
                    places: key?.place_of_posting?.map((item) =>
                        `${item.location_name}, ${item?.state_name ? item?.state_name : ''}`).join(','),
                },
                status: {
                    value1: key?.status,
                    value2: 'CEO Approval Pending',
                    value3: 'HOD Approval Pending',
                },
                feedBack: {
                    comment_1: "Interpersonal skills are great. Technically sound",
                    comment_2: "Matching the skill sets we required. Considering for CEO round",
                },
                value: key,
                dateofRequest: moment(key?.raised_on).format('DD MMM YYYY'),
                designation: key?.designation_name,
                type:  changeTypeOfOpening( key?.type_of_opening ) ,
                timeFrame: key?.vacancy_frame,
                noofVacancies: key?.no_of_vacancy,
                NoticePeriod: key?.vacancy_frame,
                Title:key?.title ? key?.title : 'N/A' 
            };
        }) : [];


    const columns = [
        {
            field: "fID",
            headerName: "ID",
            width: 50,
            renderCell: (params) => (
                <>
                    <p className="color-blue">{params.row?.fID}</p>
                </>

            ),
        },
        {
            field: "Title",
            headerName: "Title",
            width: 140,
        },
        {
            field: "dateofRequest",
            headerName: "Date of Request",
            width: 140,
        },
        {
            field: "projectDepartment",
            headerName: "Project/Department",
            width: 160,
            renderCell: (params) => (
                <div className='projectinfo empinfo lineBreack p-0'>
                    <p>{params.row?.projectDepartment?.name},</p>
                    <p>{params.row?.projectDepartment?.department}</p>
                </div>
            ),
        },
        {
            field: "designation",
            headerName: "Designation",
            width: 300,
        },
        {
            field: "type",
            headerName: "Type",
            type: "number",
            width: 180,
            renderCell: (params) => (
                <div className="recomd_tag lineBreack p-0">
                    <span className="">{params.row?.type}</span>
                </div>
            ),
        },
        {
            field: "timeFrame",
            headerName: "Time Frame(In Days)",
            type: "number",
            width: 150,

        },
        {
            field: "noofVacancies",
            headerName: "No. of Vacancies",
            type: "number",
            width: 150,

        },
        {
            field: "placeofPosting",
            headerName: "Place of Posting",
            type: "number",
            width: 150,
            renderCell: (params) => (
                <div className="recomd_tag lineBreack p-0">
                    <span className="">{params.row?.placeofPosting?.places}</span>
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            type: "number",
            width: 160,
            renderCell: (params) => (
                <div className='stats_txt lineBreack p-0'>
                    <h6 className='stats_hdng'>
                        {params.row?.status?.value1} ,
                    </h6>
                    {
                        params.row?.value?.activity_data?.length !== 0 &&
                        params.row?.value?.activity_data?.map((item, index) => {
                            return (
                                <span key={index}>
                                    {item?.type === 'raised'
                                        ? `Added By ${item?.name}`  // Name first when `type` is 'raised'
                                        : `${item?.name} ${item?.status},`}
                                </span>
                            );
                        })
                    }

                </div>
            ),
        },
        {
            field: "mprDoc",
            headerName: "MPR Doc",
            type: "number",
            width: 120,
            renderCell: (params) => (

                <>
                    <div className='d-flex align-items-center justify-content-evenly'>

                        {
                           !(params.row?.value?.requisition_form?.includes('undefined')) && (
                                <>
                                    <a
                                        href={params.row?.value?.requisition_form}
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="downlddoc">
                                            <PiDownloadSimpleFill size={20} />
                                        </button>
                                    </a>

                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => handleOpenPreviewImage(e, params.row?.value?.requisition_form)}
                                    >
                                        <FaEye size={20} />
                                    </div>
                                </>
                            )
                        }


                    </div>
                </>

            ),
        },
        {
            field: "action",
            headerName: "Action",
            type: "number",
            width: 130,
            renderCell: (params) => {
                console.log(params.row); // Log the row to check its structure

                const status = params.row?.value?.status;
                if (['Active', 'Pending'].includes(status)) {
                    return (
                        <button className='apprvbtn' onClick={(e) => handleShow(e, params.row?.value)}>
                            Approve
                        </button>
                    );
                }

                return null;
            }
        },
        {
            field: "Edit",
            headerName: "Edit",
            type: "number",
            width: 130,
            renderCell: (params) => {
                const status = params.row?.value?.status;

                if (['Active', 'Pending'].includes(status)) {
                    return (
                        <Link to={`/manpower-acquisition?id=${params.row?.value._id}`}>
                            <button className='apprvbtn'>
                                Edit Form
                            </button>
                        </Link>
                    );
                } else {
                    return "-";
                }
            }
        },

    ];


    return (
        <>
            <div className="w-100 mainprojecttable requstntable">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    // headerClassName="custom-header-class"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20]}
                    loading={ManPowerRequisition.status === 'loading'}
                    sx={{
                        minHeight: 500
                    }}

                />
            </div>
            <ApprovalModal show={show} onHide={() => setShow(false)} data={visible} />

            <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Document Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        {(() => {
                            // Check if the file is a PDF
                            const fileExtension = imageUrl && imageUrl?.split('.').pop().toLowerCase();
                            if (fileExtension === 'pdf') {
                                return (
                                    <embed
                                        src={imageUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                        style={{ borderRadius: '5px' }}
                                    />
                                );
                            }

                            // Check if the file is a DOC or DOCX
                            if (fileExtension === 'doc' || fileExtension === 'docx') {
                                return (
                                    <iframe
                                        src={`https://docs.google.com/viewer?url=${imageUrl}&embedded=true`}
                                        title="Document Preview"
                                        style={{ width: '100%', height: '400px' }}
                                    />

                                );
                            }

                            // Handle other file types or unknown formats
                            return (
                                <p>Unsupported file format. Please <a href={config.IMAGE_PATH + imageUrl} download>download the file</a>.</p>
                            );
                        })()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

