import React from "react";
import Aadhar from "../images/adhaar.jpg";
import Modal from 'react-bootstrap/Modal';

const Viewdoc_modal = (props) => {
    const { imageUrl, docName, ...modalProps } = props; // Extract imageUrl and docName from props

    console.log(imageUrl , 'this is Show the Document Modal Here to prevent the Details');
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {docName || 'Document'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-4">
                <div className="col-sm-12">
                    <div className="uplaodrow my-4">
                    {imageUrl ? (
                            <img src={imageUrl} alt="Document" className="img-fluid" />
                        ) : (
                            <p>No image available</p>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default Viewdoc_modal;
