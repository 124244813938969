import React, { useState, useEffect } from "react";
import config from "../config/config";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Viewdoc_modal from "./View-document";
import { getCandidateByEmailName } from "./helper/Api_Helper";
import { FaCloudUploadAlt } from "react-icons/fa";
import axios from "axios";
import { apiHeaderTokenMultiPart } from "../config/api_header";
import { toast } from "react-toastify";

const IMAGE_PATH = config.IMAGE_PATH;
export default function Verify_docs_modal(props) {
  const [viewmodalShow, setViewModalShow] = useState(false);
  const [employeeDoc, setEmployeeDoc] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedDocName, setSelectedDocName] = useState("");

  const { Document, candidate, fetchRecords } = props;

  const handleUploadClick = (documentName) => {
    const fileInput = document.createElement("input");
    console.log(documentName, 'this is File Name Here To be Prevent The Document Uploads Data');
    fileInput.type = "file";
    fileInput.accept = ".jpg,.jpeg,.png";
    fileInput.onchange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (documentName === 'Aadhar Back' || documentName === 'Aadhar Front' || documentName === 'Pancard') {
          // 'KYC', 'Document',
          // {["Aadhar Back", "Aadhar Front", "Pancard", "10th Marksheet", "12th Marksheet", "Graduation Degree", "Post Graduation / Other Educational Document"].map((requiredDoc, index) => {
          const formData = new FormData();
          formData.append("filename", selectedFile);
          formData.append("_id", candidate?._id);
          formData.append("doc_category", 'KYC');
          formData.append("sub_doc_category", 'Document');
          formData.append("doc_name", documentName);
          uploadDocument(formData)
        } else if (documentName === '10th Marksheet' || documentName === '12th Marksheet' || documentName === 'Graduation Degree' || documentName === 'Post Graduation / Other Educational Document') {
          const formData = new FormData();
          formData.append("filename", selectedFile);
          formData.append("_id", candidate?._id);
          formData.append("doc_category", 'Educational');
          formData.append("sub_doc_category", 'Marksheet');
          formData.append("doc_name", documentName);
          uploadDocument(formData)
        }
      }
    };
    fileInput.click();
  };

  // Example function to handle the upload logic
  const uploadDocument = async (payload) => {
    try {

      let response = await axios.post(`${config.API_URL}uploadEmployeeKycDocs`, payload, apiHeaderTokenMultiPart(config.API_TOKEN))

      if (response.status === 200) {
        toast.success(response.data?.message || "Document Uploaded Successfully")
        fetchRecords();
      } else {
        // Handle upload failure
        toast.error(response.data?.message || "SomeTing Went Wrong")
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(error?.response.data?.message || "SomeTing Went Wrong")
    }
  };




  const handleViewClick = (fileName, docName) => {
    setSelectedImage(IMAGE_PATH + fileName);
    setSelectedDocName(docName);
    setViewModalShow(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0 px-4" closeButton>
          <Modal.Title>Documents Attached</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-top">
          <Table className="docstable">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Document Name</th>
                <th>Category</th>
                <th>Attached Date</th>
                <th>Uploaded By</th>
                <th colspan="2">Action</th>
              </tr>
            </thead>
            <tbody>

              {
                Document && Document.length > 0 ? (
                  Document?.map((doc, index) => {
                    return (
                      <>
                        <tr key={doc._id}>
                          <td>{index + 1}</td>
                          <td>{doc.doc_name}</td>
                          <td>{doc.doc_category}</td>
                          <td>{new Date(doc.add_date).toLocaleDateString()}</td>
                          <td>{employeeName}</td>
                          <td>
                            <div className="d-flex flex-row gap-3 justify-content-end">
                              <button
                                className="viewfile"
                                onClick={() => handleViewClick(doc.file_name, doc.doc_name)}
                              >
                                View
                              </button>
                              <button className="set_verify">Verify</button>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr>
                  <td colSpan="6">No documents available</td>
                </tr>
                )
              }
            </tbody>

          </Table>
          <div className="read-btn d-flex justify-content-center align-items-center my-3">
            <button className="px-5 w-small btn">Verify All</button>
          </div>
        </Modal.Body>
      </Modal>
      <Viewdoc_modal show={viewmodalShow} onHide={() => setViewModalShow(false)} imageUrl={selectedImage} docName={selectedDocName} />

    </>
  );
}
