import React, { useMemo, useState } from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { MdOutlineEventNote } from "react-icons/md";
import { GoPeople } from "react-icons/go";
import { LuUserCheck } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { LuFileKey2 } from "react-icons/lu";
import { LuSettings2 } from "react-icons/lu";
import { LuLogOut } from "react-icons/lu";
import { PiGraph } from "react-icons/pi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuArrowUpSquare } from "react-icons/lu";
import { GrDocumentExcel } from "react-icons/gr";
import { MdManageAccounts } from "react-icons/md";
import { MdSpatialTracking } from "react-icons/md";
import { GoProjectRoadmap } from "react-icons/go";
import { FaLocationDot } from "react-icons/fa6";
import { TbTimeDurationOff } from "react-icons/tb";
import { GiDuration } from "react-icons/gi";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { MdSpatialAudioOff } from "react-icons/md";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { MdRealEstateAgent } from "react-icons/md";
import { SiPrivatedivision } from "react-icons/si";
import { CgPathDivide } from "react-icons/cg";
import { CiBank } from "react-icons/ci";
import { PiTrainRegionalFill } from "react-icons/pi";
import { CgDisplayGrid } from "react-icons/cg";
import { MdSensorOccupied } from "react-icons/md";
import { FaTags } from "react-icons/fa6";
import { MdCastForEducation } from "react-icons/md";
import { MdAddLocationAlt } from "react-icons/md";
import { TbBrandMastercard } from "react-icons/tb";
import { FaBehanceSquare } from "react-icons/fa";
import { MdHolidayVillage } from "react-icons/md";
import { SiPayloadcms } from "react-icons/si";
import { FaSackDollar } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";
import { FaExchangeAlt } from "react-icons/fa";
import { IoIosPersonAdd } from "react-icons/io";
import { FaUser } from 'react-icons/fa';
import config from "../../config/config";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";


function Sidebars() {
    const [collapsed, setCollapsed] = useState(true);
    const navigation = useNavigate()


    let LoggedUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('admin_role_user')) || {}
    }, [])

    const handleLogOut = () => {
        localStorage.removeItem('admin_role_user')
        navigation('/login')
    }

    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <>
            <Sidebar className={`sitesidebar app ${toggled ? "toggled" : ""}`} style={{ height: "100%", position: "fixed" }} collapsed={collapsed} toggled={toggled}
                handleToggleSidebar={handleToggleSidebar} handleCollapsedChange={handleCollapsedChange}>
                <main>
                    <Menu className="sidelogobox">
                        {collapsed ? (<MenuItem icon={<MenuIcon />} onClick={handleCollapsedChange}></MenuItem>) : (
                            <MenuItem prefix={<MenuIcon />} onClick={handleCollapsedChange} >
                                <div className="dashlogo">
                                    <img src={config.LOGO_PATH} alt={config.COMPANY_NAME} />
                                </div>
                            </MenuItem>
                        )}
                    </Menu>

                    <Menu className="sidemenus noline">

                        {/* DashBoard Validation */}

                        <SubMenu className="active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />} label="Dashboard">
                            <MenuItem className="sub_active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />}> Dashboard </MenuItem>

                            <MenuItem className="" icon={<PiGraph />} component={<Link to="/analytics" />}> Analytics </MenuItem>
                        </SubMenu>
                        {/* 
                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'dashboard') ?
                                (
                                    <>
                                        <SubMenu className="active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />} label="Dashboard">
                                            <MenuItem className="sub_active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />}> Dashboard </MenuItem>
                                            {
                                                LoggedUser?.permissions?.find(permission => permission.slug === 'analytics')
                                                &&
                                                <MenuItem className="" icon={<PiGraph />} component={<Link to="/analytics" />}> Analytics </MenuItem>
                                            }
                                        </SubMenu>
                                    </>
                                )
                                : null
                        } */}



                        {/* Master Nav Page--------------******************************* */}


                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'master') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>Master</p>
                                        </div>
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'projects') &&
                                            <MenuItem icon={<TokenOutlinedIcon />} component={<Link to="/projects" />} >  Project </MenuItem>
                                        }
                                    </>
                                )
                                : null
                        }

                        {/* ATS Menu List Option */}

                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'ats') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>ATS</p>
                                        </div>
                                        {/* Ats Menu Access Role */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'ats')
                                            &&
                                            <MenuItem icon={<MdOutlineEventNote />} component={<Link to="/ats" />} >  Application Tracking System</MenuItem>
                                        }
                                        {/* Assign The Assessment Managment Assign Task  */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'assessment')
                                            &&
                                            <MenuItem icon={<MdManageAccounts />} component={<Link to="/assessment" />} >Manage Assessment</MenuItem>
                                        }
                                    </>
                                ) : null
                        }

                        {/* Analytics Menu Ats Tracker */}

                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'analytics') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>Analytic Trackers</p>
                                        </div>

                                        {/* Analytics Menu Dashboard */}
                                        {/* Employee Tracker Charge */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'employementtracker')
                                            &&
                                            <MenuItem className="" icon={<MdSpatialTracking />} component={<Link to="/employementtracker" />}> Employment Tracker </MenuItem>
                                        }
                                        {/* ATS Tracker */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'ats')
                                            &&
                                            <MenuItem className="" icon={<MdOutlineEventNote />} component={<Link to="/ats" />}> Job (ATS) Tracker </MenuItem>
                                        }
                                        {/* Project Tracker */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'project-tracker')
                                            &&
                                            <MenuItem className="" icon={<GoProjectRoadmap />} component={<Link to="/project-tracker" />}> Project Tracker </MenuItem>
                                        }
                                        {/* alumni Tracker */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'employee-alumni-tracker')
                                            &&
                                            <MenuItem className="" icon={<GoProjectRoadmap />} component={<Link to="/employee-alumni-tracker" />}> Employee Alumni Tracker </MenuItem>
                                        }

                                    </>
                                ) : null
                        }

                        {/* Manpower Requisition Form */}

                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'manpower-acquisition') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>Manpower Requisition Form</p>
                                        </div>

                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'manpower-acquisition') &&
                                            <MenuItem className="" icon={<IoIosPersonAdd />} component={<Link to="/manpower-acquisition" />}> Add From  </MenuItem>
                                        }
                                        {/* List of ManPower Acquisition Form */}

                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'manpower-acquisition-list') &&
                                            <MenuItem className="" icon={<MdOutlineEventNote />} component={<Link to="/manpower-acquisition-list" />}> List of Froms </MenuItem>
                                        }

                                    </>
                                ) : null
                        }


                        {/* People List Menu */}

                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'People') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>People</p>
                                        </div>
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'People') &&
                                            // manage Here People sub Menu
                                            <SubMenu className="" icon={<GoPeople />} label="People">
                                                {
                                                    (LoggedUser?.permissions?.find(permission => permission.slug === 'employee-extension') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                                    <MenuItem className="sub_active" icon={<FaArrowTrendUp />} component={<Link to="/employee-extension" />}> Extension </MenuItem>
                                                }
                                                {
                                                    (LoggedUser?.permissions?.find(permission => permission.slug === 'employee-appraisal') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                                    <MenuItem className="sub_active" icon={<LuArrowUpSquare />} component={<Link to="/employee-appraisal" />}> Appraisal </MenuItem>
                                                }
                                                {
                                                    (LoggedUser?.permissions?.find(permission => permission.slug === 'contract-closure') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                                    <MenuItem className="sub_active" icon={<GrDocumentExcel />} component={<Link to="/contract-closure" />}> Full & Final </MenuItem>
                                                }
                                                {
                                                    (LoggedUser?.permissions?.find(permission => permission.slug === 'employee-list') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                                    <MenuItem className="sub_active" icon={<GoPeople />} component={<Link to="/employee-list" />}> People </MenuItem>
                                                }
                                            </SubMenu>
                                        }
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'attendance-index') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                            <MenuItem icon={<LuUserCheck />} component={<Link to="/attendance-index" />} > Attendance</MenuItem>
                                        }
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'payroll') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                            <MenuItem icon={<MdOutlinePayments />} component={<Link to="/payroll" />} > Payroll</MenuItem>
                                        }
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'policy') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                            <MenuItem icon={<LuFileKey2 />} component={<Link to="/policy" />} >  Policies</MenuItem>
                                        }
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'setting') && LoggedUser?.permissions?.find(permission => permission.slug === 'People')) &&
                                            <MenuItem icon={<LuSettings2 />} component={<Link to="/setting" />} > Setting</MenuItem>
                                        }
                                    </>
                                ) : null
                        }
                        <MenuItem onClick={handleLogOut} icon={<LuLogOut />} > Logout</MenuItem>

                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'role-users') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>Role Users</p>
                                        </div>
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'add-role-user') && LoggedUser?.permissions?.find(permission => permission.slug === 'role-users')) &&
                                            <MenuItem icon={<FaUser />} component={<Link to="/add-role-user" />} > Add Role User </MenuItem>
                                        }
                                        {
                                            (LoggedUser?.permissions?.find(permission => permission.slug === 'role-users-list') && LoggedUser?.permissions?.find(permission => permission.slug === 'role-users')) &&
                                            <MenuItem icon={<MdManageAccounts />} component={<Link to="/role-users-list" />} > List Of Role Users </MenuItem>
                                        }
                                    </>
                                ) : null
                        }


                        {/* {

                        } */}



                        {
                            LoggedUser?.permissions?.find(permission => permission.slug === 'master') ?
                                (
                                    <>
                                        <div className="menulables">
                                            <p>All Master</p>
                                        </div>
                                        {/* Add Location */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'add-location' && 'master') &&
                                            <SubMenu className="" icon={<FaLocationDot />} label="Location">
                                                <MenuItem className="sub_active" icon={<MdAddLocationAlt />} component={<Link to="/add-location" />}> Add Location </MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Designation */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'add-designation' && 'master') &&
                                            <SubMenu className="" icon={<TbBrandMastercard />} label="Designation">
                                                <MenuItem className="sub_active" icon={<FaArrowTrendUp />} component={<Link to="/add-designation" />}> Add Designation</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Duration */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'duration' && 'master') &&
                                            <SubMenu className="" icon={<TbTimeDurationOff />} label="Duration">
                                                <MenuItem className="sub_active" icon={<GiDuration />} component={<Link to="/duration" />}>Duration</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Department */}
                                        {
                                            LoggedUser?.permissions?.find(permission => permission.slug === 'department' && 'master') &&
                                            <SubMenu className="" icon={<FaPersonBreastfeeding />} label="Department">
                                                <MenuItem className="sub_active" icon={<MdSpatialAudioOff />} component={<Link to="/department" />}>Department</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add State */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'state' && 'master') &&
                                            <SubMenu className="" icon={<MdOutlineRealEstateAgent />} label="State">
                                                <MenuItem className="sub_active" icon={<MdRealEstateAgent />} component={<Link to="/state" />}>State</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Division */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'division' && 'master') &&
                                            <SubMenu className="" icon={<SiPrivatedivision />} label="Division">
                                                <MenuItem className="sub_active" icon={<CgPathDivide />} component={<Link to="/division" />}>Division</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Bank  */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'bank' && 'master') &&
                                            <SubMenu className="" icon={<CiBank />} label="Bank">
                                                <MenuItem className="sub_active" icon={<CiBank />} component={<Link to="/bank" />}>Bank</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Region */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'region' && 'master') &&
                                            <SubMenu className="" icon={<PiTrainRegionalFill />} label="Region">
                                                <MenuItem className="sub_active" icon={<PiTrainRegionalFill />} component={<Link to="/region" />}>Region</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Dispensary */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'dispensary' && 'master') &&
                                            <SubMenu className="" icon={<CgDisplayGrid />} label="Dispensary">
                                                <MenuItem className="sub_active" icon={<CgDisplayGrid />} component={<Link to="/dispensary" />}>Dispensary</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Occupations */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'occupation' && 'master') &&
                                            <SubMenu className="" icon={<MdSensorOccupied />} label="Occupation">
                                                <MenuItem className="sub_active" icon={<MdSensorOccupied />} component={<Link to="/occupation" />}>Occupation</MenuItem>
                                            </SubMenu>
                                        }
                                        {/*  Add Tags  */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'tags' && 'master') &&
                                            <SubMenu className="" icon={<FaTags />} label="Tags">
                                                <MenuItem className="sub_active" icon={<FaTags />} component={<Link to="/tags" />}>Tags</MenuItem>
                                            </SubMenu>

                                        }
                                        {/* Add Eudcation */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'education' && 'master') &&
                                            <SubMenu className="" icon={<MdCastForEducation />} label="Education">
                                                <MenuItem className="sub_active" icon={<MdCastForEducation />} component={<Link to="/education" />}>Education</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Benifits */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'benefits' && 'master') &&
                                            <SubMenu className="" icon={<FaBehanceSquare />} label="Benefits">
                                                <MenuItem className="sub_active" icon={<FaBehanceSquare />} component={<Link to="/benefits" />}>Benefits</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Holiday */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'holiday' && 'master') &&
                                            <SubMenu className="" icon={<MdHolidayVillage />} label="Holiday">
                                                <MenuItem className="sub_active" icon={<MdHolidayVillage />} component={<Link to="/holiday" />}>Holiday</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Cms */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'cms' && 'master') &&
                                            <SubMenu className="" icon={<SiPayloadcms />} label="Cms">
                                                <MenuItem className="sub_active" icon={<SiPayloadcms />} component={<Link to="/cms" />}>Cms</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add SalaryRange */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'salary-range' && 'master') &&
                                            <SubMenu className="" icon={<FaSackDollar />} label="SalaryRange">
                                                <MenuItem className="sub_active" icon={<FaSackDollar />} component={<Link to="/salary-range" />}>Salary Range</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Leave Category */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'leave' && 'master') &&
                                            <SubMenu className="" icon={<FaCalendarDays />} label="Leave">
                                                <MenuItem className="sub_active" icon={<FaCalendarDays />} component={<Link to="/leave" />}>Leave</MenuItem>
                                            </SubMenu>
                                        }
                                        {/* Add Grade list */}
                                        {
                                            LoggedUser?.permissions?.some(permission => permission?.slug === 'gradelist' && 'master') &&
                                            <SubMenu className="" icon={<FaExchangeAlt />} label="GradeList">
                                                <MenuItem className="sub_active" icon={<FaExchangeAlt />} component={<Link to="/gradelist" />}>GradeList</MenuItem>
                                            </SubMenu>
                                        }
                                    </>
                                ) : null
                        }


                    </Menu>
                </main>
            </Sidebar>
        </>
    );
}
export default Sidebars;
