import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { getEmployeeCountForMapChart } from '../employee/helper/Api_Helper';
import config from '../config/config';

const GOOGLE_MAP_KEY = config.GOOGLE_MAP_KEY;

const mapContainerStyle = {
    width: '100%',
    height: '100vh',
};

const center = {
    lat: 22.9734, // Center of India
    lng: 78.6569,
};

const indiaBounds = {
    north: 37.6,
    south: 6.7,
    west: 68.7,
    east: 97.25,
};

const EmployeeGeographicView = ({ projectId }) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const mapRef = useRef(null);

    useEffect(() => {
        const fetchEmployeeCountForMapChart = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await getEmployeeCountForMapChart(projectId);
                if (response && response.status) {
                    setLocations(response.data || []);
                } else {
                    setLocations([]);
                }
            } catch (error) {
                setError('Error fetching employee count data. Please try again later.');
                console.error("Error fetching employee count data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeCountForMapChart();
    }, [projectId]);

    const handleMarkerClick = useCallback((location) => {
        setSelectedLocation(location);
    }, []);

    const totalEmployeeCount = locations.reduce((total, loc) => total + loc.count, 0);

    return (
        <div className='geomap'>
            {loading && (
                <div className='loader'>
                    <p>Loading...</p>
                </div>
            )}
            {error && (
                <div className='error'>
                    <p>{error}</p>
                </div>
            )}
            <LoadScript
                googleMapsApiKey={GOOGLE_MAP_KEY}
                libraries={['places']}
            >
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={5} // Adjust zoom level as needed
                    options={{ restriction: { latLngBounds: indiaBounds, strictBounds: true } }}
                    onLoad={map => mapRef.current = map}
                >
                    {locations.map((location, index) => (
                        <Marker
                            key={index}
                            position={{ lat: location.lat, lng: location.lng }}
                            onClick={() => handleMarkerClick(location)}
                            title={`${location.name} - ${location.count}`}
                        />
                    ))}

                    {selectedLocation && (
                        <InfoWindow
                            position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                            onCloseClick={() => setSelectedLocation(null)}
                        >
                            <div>
                                <h4>{selectedLocation.name}</h4>
                                <p>Count: {selectedLocation.count}</p>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            </LoadScript>
            {!loading && !error && (
                <>
                    <div className='location_employees'>
                        <ul>
                            {locations.map((location, index) => (
                                <li key={index}>{location.name} ({location.count})</li>
                            ))}
                        </ul>
                    </div>
                    <div className='totl_empcount'>
                        <h5>Total Employee Count: <span>{totalEmployeeCount}</span></h5>
                    </div>
                </>
            )}
        </div>
    );
};

export default EmployeeGeographicView;
