import React from "react";
import TopHeader from "./TopHeader";

const  AllHeaders = () =>{ 
    return (
        <>
         <TopHeader /> 
        </>
    );
}

export default AllHeaders;
