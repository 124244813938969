
import React from "react";

export default function EmployeeInfo() {
    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0">
                        <div className="infobox">
                            <h5>Employment Information</h5>
                            <div className="infotext mx-50 mt-3">
                                <div className="infos">
                                    <h6>Full name</h6>
                                    <p>Anshul Awasthi</p>
                                </div>
                                <div className="infos">
                                    <h6>Father’s Name</h6>
                                    <p>Srinivas Awasthi</p>
                                </div>
                                <div className="infos">
                                    <h6>Gender</h6>
                                    <p>Male</p>
                                </div>
                                <div className="infos">
                                    <h6>Marital Status</h6>
                                    <p>Single</p>
                                </div>
                                <div className="infos">
                                    <h6>Date of Joining</h6>
                                    <p>21/03/2023</p>
                                </div>
                                <div className="infos">
                                    <h6>Probation Completion Date</h6>
                                    <p>22/06/2023</p>
                                </div>
                                <div className="infos">
                                    <h6>Designation</h6>
                                    <p>Sr. Engineer</p>
                                </div>
                                <div className="infos">
                                    <h6>Branch </h6>
                                    <p>Gail - Odisha</p>
                                </div>
                                <div className="infos">
                                    <h6>Occupation</h6>
                                    <p>Engineer</p>
                                </div>
                                <div className="infos">
                                    <h6>Department</h6>
                                    <p>Engineering</p>
                                </div>
                                <div className="infos">
                                    <h6>Division</h6>
                                    <p>Engineering</p>
                                </div>
                                <div className="infos">
                                    <h6>Grade</h6>
                                    <p>NMG</p>
                                </div>
                            </div>
                            <h5>Education</h5>
                            <div className="infotext mx-50">
                                <div className="infos">
                                    <h6>B.Sc in Computer Science</h6>
                                    <p>Lucknow University.</p>
                                </div>
                                <div className="infos">
                                    <h6>Higher Secondary School Certificate</h6>
                                    <p>Mordern School , CBSE Board</p>
                                </div>
                                <div className="infos">
                                    <h6>Experience in Total</h6>
                                    <p>5 Years 10 months</p>
                                </div>
                                <div className="infos">
                                    <h6>Relevant Experience</h6>
                                    <p>3 Years 10 months</p>
                                </div>
                                <div className="infos">
                                    <h6>Current CTC</h6>
                                    <p>4.5 lpa</p>
                                </div>
                                <div className="infos">
                                    <h6>Expected CTC</h6>
                                    <p>6.5 lpa</p>
                                </div>
                                <div className="infos">
                                    <h6>Notice Period</h6>
                                    <p>30 Days</p>
                                </div>
                                <div className="infos">
                                    <h6>Last Working day </h6>
                                    <p>-</p>
                                </div>
                                <div className="infos">
                                    <h6>Applied from</h6>
                                    <p>Indeed</p>
                                </div>
                                <div className="infos">
                                    <h6>Reference employee</h6>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
