import React, { useState } from "react";
import { HiOutlineUsers } from "react-icons/hi2";
import { FaUsersRectangle } from "react-icons/fa6";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

// import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import MenuIcon from '@mui/icons-material/Menu';

import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

function Sidebars() {
    //const { collapseSidebar } = useProSidebar();
    const [collapsed, setCollapsed] = useState(false);

    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <>
            <Sidebar className={`sitesidebar app ${toggled ? "toggled" : ""}`} style={{ height: "100%", position: "fixed" }} collapsed={collapsed} toggled={toggled}
                handleToggleSidebar={handleToggleSidebar} handleCollapsedChange={handleCollapsedChange}>
                <main>
                    <Menu className="sidelogobox">
                        {collapsed ? (<MenuItem icon={<MenuIcon />} onClick={handleCollapsedChange}></MenuItem>) : (
                            <MenuItem prefix={<MenuIcon />} onClick={handleCollapsedChange} >
                                <div className="dashlogo">
                                    <img src={logo} alt="logoImg"/>
                                </div>
                            </MenuItem>
                        )}
                    </Menu>
                    <Menu className="sidemenus">
                        <MenuItem className="active" icon={<FaUsersRectangle />} > <Link to="/upcoming"> Upcoming Interview</Link></MenuItem>
                        <MenuItem icon={<HiOutlineUsers />} > <Link to="/today-interview"> Today Interview</Link></MenuItem>
                    </Menu>
                </main>
            </Sidebar>
        </>
    );
}
export default Sidebars;
