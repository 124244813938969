import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactCalendar from "react-calendar";
import Form from "react-bootstrap/Form";
import { CiCalendar } from "react-icons/ci";
import Table from "react-bootstrap/Table";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config";

export default function EmpAttendance({ data }) {
    const [open, setOpen] = useState(false);
    const [getPolicyData, setPolicyData] = useState(null);

    console.log(data , 'This is Data from the The UserDetails Hai Data');

    const percentage = 90;
    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                        <div className="d-flex flex-column gap-2">
                            <div className="dflexbtwn px-3">
                                <div className="position-relative w-smaller rounded-3">
                                    <Form.Select className="ps-4">
                                        <option>2023</option>
                                        <option>2024</option>
                                        <option>2025</option>
                                    </Form.Select>
                                    <div className="cal-icon">
                                        <CiCalendar />
                                    </div>
                                </div>
                                <div className="addattend gap-2">
                                    <Link>
                                        <button className="sitebtn" onClick={(e) => setOpen(true)}>Leave Policy</button>
                                    </Link>
                                    <Link className="ml-3 ms-2" to={`/candidate-detail?id=${encodeURIComponent(data._id)}`}>
                                        <button className="sitebtn">Add Attendance</button>
                                    </Link>
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 smalldata infobox">
                                <Table hover className="candd_table attndnc_tbl">
                                    <thead>
                                        <tr>
                                            <th>Leave</th>
                                            <th>Annual Allowed</th>
                                            <th>Raised</th>
                                            <th>Available</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* add the full length tr and No data availble */}
                                        <tr>
                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                No Records Found
                                            </td>
                                        </tr>


                                        {/* <tr onClick={() => setOpen(!open)}>
                                            <td><p className="color-blue">Jan</p> </td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        {open && (
                                            <tr>
                                                <td colSpan="4" className="p-0">
                                                    <div className="monthly_attndence">
                                                        <div className="d-flex justify-content-between flex-row header-collapse py-2">
                                                            <h5 className="w-100 mb-0 ">
                                                                January 2023 Attendance
                                                            </h5>
                                                            <div className="d-flex justify-content-end pe-3" onClick={() => setOpen(!open)}>
                                                                <MdClose />
                                                            </div>
                                                        </div>
                                                        <div className="row container gy-3 my-2">
                                                            <div className="col-lg-6 calendarVal">
                                                                <ReactCalendar disabled={true} />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="d-flex flex-column gap-1 justify-content-center align-items-center my-2">
                                                                    <div className="assementscore" style={{ width: 180, height: 180 }} >
                                                                        <CircularProgressbar
                                                                            value={percentage}
                                                                            strokeWidth={10}
                                                                            text={`${percentage}%`}
                                                                        />
                                                                    </div>
                                                                    <p className="atn_hdng">Overall Attendance</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td><p className="color-blue">Feb</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">Mar</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">Apr</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">May</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">June</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">July</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">Aug</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">Sep</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="color-blue">Oct</p></td>
                                            <td><p>26</p></td>
                                            <td><p>25</p></td>
                                            <td><p>1</p></td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* Adding the Leave Policy Models to show the Data  */}

            <Modal show={open} onHide={() => setOpen(false)} size="lg" className="jobtemp_modal offermodal">
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>
                        <h4>Leave Policy</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 smalldata infobox changeColor">
                        <Table hover className="candd_table attndnc_tbl">
                            <thead>
                                <tr>
                                    <th>Leave</th>
                                    <th>Annual Allowed</th>
                                    <th>Raised</th>
                                    <th>Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* add the full length tr and No data availble */}
                                {
                                    data?.leave_policy && data?.leave_policy?.length > 0 ?
                                        data?.leave_policy.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td><p className="color-blue">{item?.name}</p></td>
                                                        <td><p>{item?.allowed}</p></td>
                                                        <td><p>{item?.raised}</p></td>
                                                        <td><p>{item?.raised}</p></td>
                                                    </tr>
                                                </>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                No Records Found
                                            </td>
                                        </tr>
                                }



                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>




        </>
    );
}
