

import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import config from '../config/Config';
import { apiHeaderToken } from '../config/ApiHeaders';
import axios from 'axios';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import Rating from 'react-rating';

const RateModal = (props) => {
  const [skill, setSkill] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [rating, setRating] = useState(0);
  const [date, setDate] = useState('');
  const [comment, setComment] = useState('');

   console.log(skill,communication,rating,date,comment)

  const handleSubmitFeedBack = async (e) => {
    e.preventDefault();
    const payload = {
      candidate_id: props?.rateProp?.candidateId,
      applied_job_id: props?.rateProp?.jobId,
      interviewer_id: props?.rateProp?.interviewerId,
      comment: comment,
      rating: rating,
      communication: communication,
      skills: skill,
      feedback_date: date
    };

    try {
      const response = await axios.post(`${config.API_URL}saveFeedback`, payload, apiHeaderToken(config.API_TOKEN));
      console.log(response);
      props?.setModaltoast({
        status: true,
        message: response.data.message
      });
      props.onHide();
      // Reset the form fields after successful submission
      setSkill(0);
      setCommunication(0);
      setRating(0);
      setDate('');
      setComment('');
    } catch (error) {
      console.error(error);
      props?.setModaltoast({
        status: false,
        message: 'An error occurred while submitting feedback. Please try again.'
      });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Feedback
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4">
        <div className="col-sm-12">
          <Form onSubmit={handleSubmitFeedBack}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formSkills">
                  <Form.Label>Skills</Form.Label>
                  <Col>
                  <Rating
                    initialRating={skill}
                    value={skill}
                    onChange={setSkill}
                    fractions={2}
                    fullSymbol={<FaStar color="gold" size={24} />}
                    halfSymbol={<FaStarHalfAlt color="gold" size={24} />}
                    emptySymbol={<FaRegStar color="gold" size={24} />}
                    stop={10}
                  />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCommunication">
                  <Form.Label>Communication</Form.Label>
                  <Col>
                  <Rating
                    initialRating={communication}
                    value={communication}
                    onChange={setCommunication}
                    fractions={2}
                    fullSymbol={<FaStar color="gold" size={24} />}
                    halfSymbol={<FaStarHalfAlt color="gold" size={24} />}
                    emptySymbol={<FaRegStar color="gold" size={24} />}
                    stop={10}
                  />
                   </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formRating">
                  <Form.Label>Rating</Form.Label>
                  <Col>
                  <Rating
                    initialRating={rating}
                    value={rating}
                    onChange={setRating}
                    fractions={2}
                    fullSymbol={<FaStar color="gold" size={24} />}
                    halfSymbol={<FaStarHalfAlt color="gold" size={24} />}
                    emptySymbol={<FaRegStar color="gold" size={24} />}
                    stop={5}
                  />
                   </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3 position-relative" controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3 ratetxtarea" controlId="formComment">
                  <Form.Label>Additional Comment (if any)</Form.Label>
                  <Form.Control
                    as="textarea"
                    aria-label="With textarea"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Enter Comment"
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="text-center">
              <button type="submit" className="sitebtn mt-4 btn btn-primary ratebtn">
                <CheckCircleIcon /> Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RateModal;
